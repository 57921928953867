var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('extended_reputation')},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',[_vm._v(" "+_vm._s(_vm.$t('rank_system_page_description'))+" "),_c('span',{staticClass:"cursor-pointer underline hover:opacity-0-7",on:{"click":function($event){_vm.helpMessageIsOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('more'))+" ")])])]},proxy:true},{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{staticClass:"relative"},[(!_vm.getTagsByFieldKey("extended_reputation"))?[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'xp_action_message',
            'placeholders': _vm.RANK_PLACEHOLDERS,
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'hasAnchor': true,
          },
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'allow_to_reduce_rank',
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'user_ranks_by_xp',
            'hasAnchor': true,
          }
        }}}),_c('switch-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.stateChatConfigPath,
            'key': 'user_ranks_by_reputation',
            'hasAnchor': true,
          }
        }}}),_c('scoring-system',{staticClass:"mt-2",attrs:{"use-reputation":_vm.$store.state.chatState.chat.config.user_ranks_by_reputation,"use-xp":_vm.$store.state.chatState.chat.config.user_ranks_by_xp,"ranks":_vm.frontendRanks},on:{"input":_vm.onRanksChange}})]:_c('div',{staticStyle:{"height":"300px"}},[_c('not-available-options-overlay',{attrs:{"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.getTagsByFieldKey('extended_reputation')}})],1)],2),_c('a-modal',{staticClass:"rank-system-modal",attrs:{"title":_vm.$t('tab_reputation'),"centered":"","footer":null},model:{value:(_vm.helpMessageIsOpen),callback:function ($$v) {_vm.helpMessageIsOpen=$$v},expression:"helpMessageIsOpen"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('rank_system_page_help_message'))}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }










































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import ScoringSystem from '@/components/ScoringSystem.vue'
import PageTitle from '@/components/PageTitle.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { InputSetups } from '../../../mixins/input-setups'
import TariffsTagsHelper from '../../../mixins/TariffsTagsHelper'
import Placeholders from '@/mixins/placeholders/placeholders'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins } from 'vue-property-decorator'
import { Guid } from 'guid-typescript'

@Component({
  'components': {
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    ScoringSystem,
    PageTitle,
  },
  data() {
    return {
      MediaEditorMode,
    }
  }
})
export default class RankSystem extends Mixins<UseFields, InputSetups, TariffsTagsHelper, Placeholders>(UseFields, InputSetups, TariffsTagsHelper, Placeholders) {
  helpMessageIsOpen = false

  get frontendRanks(): Array<any> {
    return this.$store.state.chatState.chat.config.user_ranks
      .map((rank:any, index:number, arr:Array<any>) => {
        if (index === 0) {
          return { key: Guid.create().toString(), xp: null, reputation: null, title: rank.title }
        } else {
          return {
            key: Guid.create().toString(),
            xp: arr[index - 1].xp,
            reputation: arr[index - 1].reputation,
            title: rank.title,
          }
        }
      })
  }

  configRanks(ranks: Array<any>): Array<any> {
    return ranks.slice()
      .map((rank:any, index:number, arr:Array<any>) => {
        if (index === arr.length - 1) {
          return {
            xp: Number.MAX_SAFE_INTEGER,
            reputation: Number.MAX_SAFE_INTEGER,
            title: rank.title,
          }
        } else {
          return {
            xp: arr[index + 1].xp,
            reputation: arr[index + 1].reputation,
            title: rank.title,
          }
        }
      })
  }

  onRanksChange(ranks: Array<any>) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.user_ranks = this.configRanks(ranks)
      }
    })
  }
}
